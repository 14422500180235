import {Routes} from "./const/Routes";
import {Navigate} from "react-router-dom";

interface Prop {
    children: React.ReactNode
}

export function PrivateRoutes({ children }: Prop) {
    const jwt = localStorage.getItem("jwt");
    return jwt ? <>{children}</> : <Navigate to={Routes.LOGIN} />;
}